/** * 元宇宙入口  */
<template>
  <metaversePC v-if="pathInfo"></metaversePC>
  <router-view v-else></router-view>
</template>

<script>
import metaversePC from '../../views/metaverse/pc/Index.vue';
export default {
  name: "MetaverseIndex",
  components: {metaversePC},
  data() {
    return {
      showHeaderMenu: ["","Course"],
      pathInfo: true,
    }
  },
  mounted() {
    this.pathInfo = this.showHeaderMenu.indexOf(window.location.pathname.split("/")[1]) !== -1;
  },
  watch: {
    $route() {
      this.pathInfo = !window.location.pathname.split("/")[1];
      this.pathInfo = this.showHeaderMenu.indexOf(window.location.pathname.split("/")[1]) !== -1;
    }
  },
}
</script>

<style scoped>

</style>